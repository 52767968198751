<template>
  <div>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>General Settings</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-tabs>
        <v-tab dark>Inventory</v-tab>
        <v-tab dark>Administration</v-tab>
        <v-tab dark>Open Documents</v-tab>
        <!--        <v-tab dark>Data Source</v-tab>-->
        <v-tab dark>Data</v-tab>
        <v-tab dark>Display</v-tab>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <!-- inner tabs -->
              <v-tabs background-color="tabToolbar">
                <v-tab>Items</v-tab>
                <v-tab>Reporting</v-tab>
                <v-tab-item>
                  <v-row class="pa-2">
                    <v-col cols="4">
                      <v-row dense>
                        <v-col cols="12">
                          <v-autocomplete
                            outlined
                            dense
                            v-model="record.DfltWhs"
                            :items="warehouses"
                            item-text="WhsName"
                            item-value="id"
                            label="Default Warehouse"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12">
                          <v-autocomplete
                            outlined
                            dense
                            v-model="record.GLMethod"
                            :items="glTypes"
                            item-text="name"
                            item-value="value"
                            label="Set Inv. Method By"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12">
                          <v-autocomplete
                            outlined
                            dense
                            v-model="record.DftItmsGrpCod"
                            :items="itemGroups"
                            item-text="ItmsGrpNam"
                            item-value="id"
                            label="Item Group"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="pa-2">Reporting content</v-row>
                </v-tab-item>
              </v-tabs>
              <!-- end of tabs -->
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.printUnsyncDocs"
                  label="Disable Printing of unsynchronised documents"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.copyToUnsyncDocs"
                  label="Disable Copy-To of unsynchronised documents"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  dense
                  v-model="record.SPEnabled"
                  label="Enable Stored Procedures"
                ></v-checkbox>

                <v-checkbox
                  outlined
                  dense
                  v-model="record.SPAOffline"
                  label="SAP Offline"
                ></v-checkbox>
              </v-col>
            </v-col>
          </v-row>

          <v-row class="pl-4">
            <v-radio-group
              dense
              label="Filter Warehouse By Branch"
              v-model="record.filterWarehouseByBranch"
              row
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio>
            </v-radio-group>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-card>
                <v-toolbar color="secondary" flat dense>
                  <v-toolbar-title>Open Documents To Be Synced</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="openToBeSysncedDialog()">
                    <v-icon left dark>mdi-plus</v-icon>Add New
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers"
                        :items="openDocuments"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" md="6"> </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="postToLocal"
                  label="Post Data Locally"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="fetchFromLocal"
                  label="Fetch Data Locally"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="searchLocal"
                  label="Search Data Locally"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="URL"
                  v-if="postToLocal || fetchFromLocal || searchLocal"
                  v-model="record.localUrl"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="pa-3">
            <v-card-text>Decimal Places (0..6)</v-card-text>
            <v-col cols="6">
              <v-row class="pa-3">
                <v-card-text>Display Values</v-card-text>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.SumDec"
                    label="Amounts"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.PriceDec"
                    label="Prices"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.RateDec"
                    label="Rates"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.QtyDec"
                    label="Quantities"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    v-model="record.PercentDec"
                    label="Percentages"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    v-model="record.MeasureDec"
                    outlined
                    :rules="[validateSumDec]"
                    label="Units"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.QueryDec"
                    :rules="[validateSumDec]"
                    label="Decimal in Query"
                  ></v-text-field> </v-col></v-row
            ></v-col>
            <v-col cols="6">
              <v-row class="pa-3">
                <v-card-text>DB Values</v-card-text>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.DBSumDec"
                    label="Amounts"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.DBPriceDec"
                    label="Prices"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.DBRateDec"
                    label="Rates"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.DBQtyDec"
                    label="Quantities"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    v-model="record.DBPercentDec"
                    label="Percentages"
                    :rules="[validateSumDec]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    v-model="record.DBMeasureDec"
                    outlined
                    :rules="[validateSumDec]"
                    label="Units"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="record.DBQueryDec"
                    :rules="[validateSumDec]"
                    label="Decimal in Query"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="$store.state.loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- category quick add dialog -->
    <v-dialog v-model="openSyncedDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="modalToolbar" dark>
          <v-toolbar-title>Close Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="openSyncedDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  v-model="recordData.ObjType"
                  :items="numberingData"
                  item-text="DocumentName"
                  item-value="ObjectID"
                  label="Document"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  v-model="recordData.ExtRefDocNum"
                  label="Document Number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-btn color="accent" :disabled="!isValid" @click="saveOpenDocuments">
                  <v-icon left>mdi-content-save</v-icon>Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    warehouses: {
      type: Array,
    },
    itemGroups: {
      type: Array,
    },
  },
  data: () => ({
    recordData: {},
    loader: false,
    isValid: false,
    numberingData: [],
    openDocuments: [],
    openSyncedDialog: false,
    postToLocal: false,
    fetchFromLocal: false,
    searchLocal: false,
    glTypes: [
      { name: "Item Group", value: "C" },
      { name: "Item Level", value: "L" },
      { name: "Warehouse", value: "W" },
    ],
    headers: [
      { text: "Document", value: "ObjType" },
      { text: "DocNum", value: "ExtRefDocNum" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.searchLocal = val.useLocalSearch;
      this.postToLocal = val.postToLocal;
      this.fetchFromLocal = val.fetchFromLocal;
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    validateSumDec(value) {
      if (!value || value === "") {
        this.isValid = false;
        return "Amount is required";
      }
      const num = parseInt(value);
      if (isNaN(num) || num <= 0 || num > 7) {
        this.isValid = false;
        return "Amount must be a number between 0 and 6";
      }
      this.isValid = true;
      return true;
    },
    sendData() {
      this.record.CostPrcLst = null;
      this.record.DfSVatItem = null;
      this.record.DfSVatServ = null;
      this.record.DfPVatItem = null;
      this.record.DfPVatServ = null;
      this.record.postToLocal = this.postToLocal;
      this.record.fetchFromLocal = this.fetchFromLocal;
      this.record.useLocalSearch = this.searchLocal;
      // const details = this.record;
      // loader
      this.$store.commit("loader", true);
      this.$emit("data", this.record);
    },

    getData() {
      const self = this;

      this.$store
        .dispatch("get", `/doc_model?isDoc=1`)
        .then((res) => {
          self.numberingData = res;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },

    saveOpenDocuments() {
      const data = this.recordData;
      const url = "/open-documents";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.openSyncedDialog = false;
            this.getOpenDocuments();
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },

    getOpenDocuments() {
      const self = this;
      this.$store
        .dispatch("get", `/open-documents`)
        .then((res) => {
          self.openDocuments = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openToBeSysncedDialog() {
      this.openSyncedDialog = true;
    },
  },
  created() {
    this.getOpenDocuments();
    this.getData();
  },
};
</script>
