<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor
      :initial="record"
      :itemGroups="itemGroups"
      :warehouses="warehouses"
      @data="save"
    ></editor>
    <!-- end of editor -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
  <!-- snackbar -->
</template>

<script>
import Editor from "./_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    search: "",
    record: {},
    warehouses: [],
    itemGroups: [],
  }),
  methods: {
    save(data) {
      const url = "/general_settings/1";
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res) {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show("Updated Successfully", "green");
            this.getData();
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    getData() {
      const self = this;
      this.$store.commit("loader", false);
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          self.record = res.ResponseData;
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.warehouses = res.ResponseData;
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    getItemGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res.ResponseData;
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
    this.getWarehouses();
    this.getItemGroups();
  },
};
</script>